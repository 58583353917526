(function () {
    angular.module('informaApp')
        .component('trendsViewChart', {
            templateUrl: 'components/charts/trends-view-chart/template.html',
            controller: TrendsViewChart,
            bindings: {
                source: '<',
                showNumbers: '<',
                metrics: '<',
                options: '<',
                onUpdates: '<'
            }
        });

    function TrendsViewChart($element, LineChartHelper, TrendsViewTooltipGenerator, MetricsTypes, TrendsViewMapper, ConstantsSvc) {
        this.chart = null;

        this.alternativeXAxisColors = ConstantsSvc.phaseColors;

        this.metricsTypes = MetricsTypes;
        this.lineChartHelper = LineChartHelper;
        this.trendsViewMapper = TrendsViewMapper;

        this.generateTrendsViewTooltip = (point) => TrendsViewTooltipGenerator.generate(point);

        const initialize = () => {
            const chartContainer = $element.find('.charty')[0];

            this.chart = new LineChartHelper.Chart(chartContainer, this.generateTrendsViewTooltip, this.options);

            recreateChartOnContainerSizeChanged(chartContainer, this, TrendsViewTooltipGenerator);
        };

        this.$onChanges = () => {
            if (!this.chart) {
                initialize();
            }

            updateChart(this);
        };
    }

    function recreateChartOnContainerSizeChanged(chartContainer, scope) {
        return new ResizeSensor(chartContainer, () => {
            if (scope.chart) {
                scope.chart.destroy();
            }

            scope.chart = new scope.lineChartHelper.Chart(chartContainer, scope.generateTrendsViewTooltip, scope.options);

            updateChart(scope);
        });
    }

    function updateChart(scope) {
        scope.chart.colors = scope.options.inverted ? scope.alternativeXAxisColors : scope.chart.defaultColors;

        const chartData = getChartData(scope);

        const xAxisNames = getXAxisNames(chartData);

        const getYValue = (item) => getValueByMetric(item, scope);
        scope.chart.update(mapChartData(chartData.groups), scope.showNumbers, xAxisNames, getYValue, getYAxisOptions(scope));

        emitOnUpdates.call(scope);
    }

    function emitOnUpdates() {
        if (this.onUpdates) {
            this.onUpdates(this.chart);
        }
    }

    function getChartData(scope) {
        return scope.options.inverted
            ? scope.trendsViewMapper.invertData(scope.source)
            : scope.source;
    }

    function mapChartData(source) {
        return source.map(x => {
            return {
                name: x.name,
                data: x.data
            }
        })
    }

    function getXAxisNames(source) {
        const firstLine = source.groups[0];

        return source
            ? firstLine.data.map(point => point.x)
            : [];
    }

    function getYAxisOptions(scope) {
        const prefix = scope.metrics !== scope.metricsTypes.duration
            ? '%'
            : ' yr';

        const getValues = group => group.data.map(x => getValueByMetric(x, scope));
        const values = [].concat.apply([], scope.source.groups.map(getValues));

        const max = _.max(values);
        const min = _.min(values);

        return {
            prefix: prefix,
            max: Math.ceil(max) + (max === min ? 1 : 0),
            min: Math.floor(min)
        }
    }

    function getValueByMetric(item, scope) {
        switch (scope.metrics) {
            case scope.metricsTypes.pos:
                return item.pos;
            case scope.metricsTypes.loa:
                return item.loa;
            case scope.metricsTypes.duration:
                return item.duration;
        }
    }
})();
